<template>
    <div class="platform-page">
        <HeaderAnnualMeeting />
        <div style="height: 400px;">
            
            <HeaderOa v-if="$route.name =='platform' && $route.query.type==4" />
            <HeaderPlatform v-else />
            <!-- ="$route.name =='platform'" -->
        </div>
        <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div>

        <div class="platform-wrap wid1200">

            <ul class="abroad">
                <li v-for="(item, ind) in platformData" :key="ind">
                    <a :href="item.domain" target="_blank">
                        <!-- <img src="../assets/img/oa/test1.png" alt="" /> -->
                        <img :src="item.logo" alt="" />
                        <h6>{{item.siteName}}</h6>
                        <p><span>{{item.info}}</span></p>
                    </a>
                </li>
                <!-- <li><a href="#">
                    <img src="../assets/img/oa/test1.png" alt="" />
                    <h6>ChemRxiv</h6>
                    <p><span>arXiv是一个收集物理学、数学、计算机科学与生物学等学科的论文预印本的计算机科学与生物学等学科的论文预印本的…</span></p>
                </a></li>
                -->
            </ul>

            <div class="pagination-wrap">
                <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
                    layout="total, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'
import HeaderAnnualMeeting from '../components/HeaderAnnualMeeting'
import HeaderPlatform from '../components/HeaderPreprint'
import HeaderOa from '../components/HeaderOa'

export default {
    name: '',
    components: { BreadCrumb, HeaderAnnualMeeting, HeaderPlatform, HeaderOa },
    data() {
        return {
            pN: '',
            pageName: '检索结果页',
            currentPage: 1,
            pageSize: 15, //8,
            pageNum: 1,
            sort: 1, //1相关度2在线时间
            total: 0,
            isFirstPlatform: 2,
            platformData: [],
        }
    },
    watch: {
        $route: 'init',
    },
    methods: {
        init() {
            this.currentPage = 1
            this.pageNum = 1

            if (this.$route.query.type) {
                this.isFirstPlatform = this.$route.query.type
                if (this.isFirstPlatform == 2) {
                    this.pageName = '国内预印本平台'
                } else if (this.isFirstPlatform == 4) {
                    this.pageName = 'OA平台'
                } else {
                    this.pageName = '国外预印本平台'
                }
            }
            if (this.$route.query.pN) {
                this.pN = decodeURI(this.$route.query.pN)
            } else {
                this.pN = ''
            }

            this.getData()
        },
        getData() {
            let _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/index/friendLinks',
                params: {
                    type: this.isFirstPlatform,
                    pageNum: this.currentPage, // 页码
                    pageSize: this.pageSize, //每页条数
                },
            }).then((res) => {
                if (res.data.code == 0) {
                    _this.platformData = res.data.data.rows
                    _this.total = res.data.data.total
                }
            })
        },

        handleCurrentChange(val) {
            this.currentPage = val
            this.pageNum = val
            this.getData()
        },
    },
    created() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.platform-page {
    .platform-wrap {
        min-height: 200px;
        ul.abroad {
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            li {
                //width: 196px;
                margin-right: 13px;
                margin-bottom: 40px;
                width: 229px;
                a {
                    display: block;
                    position: relative;
                    z-index: 0;
                    cursor: pointer;
                    img {
                        display: block;
                        width: 229px;
                        height: 140px;
                    }
                    h6 {
                        line-height: 50px;
                        background: #f5f7f9;
                        font-size: 20px;
                        font-weight: bold;
                        color: #2b221b;
                        text-align: center;
                        width: 229px;
                        padding: 0 10px;
                        box-sizing: border-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    p {
                        position: absolute;
                        width: 229px;
                        height: 190px;
                        left: 0;
                        top: 0;
                        background: rgba(0, 0, 0, 0.7);
                        z-index: 2;
                        padding: 24px 20px 0 20px;
                        cursor: pointer;
                        display: none;
                        span {
                            display: block;
                            font-size: 16px;
                            color: #fff;
                            line-height: 28px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 5; // 控制多行的行数
                            line-clamp: 5;
                            -webkit-box-orient: vertical;
                            box-sizing: border-box;
                        }
                    }
                }
                a:hover {
                    p {
                        display: block;
                    }
                }
            }
            li:nth-child(5n + 5) {
                margin-right: 0;
            }
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        margin-top: 30px;
    }
}
</style>
