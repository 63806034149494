<template>
    <div class="header-preprint-component">
        <div class="header-preprint-wrap">
            <img class="title-logo" src="../assets/img/oa/img-yuyinben.png" alt="" />
            <div class="search-input">
                <div class="search-border-wrap">
                    <!-- <div class="search-select-wrap">
                        <el-select ref="selectInput" v-model="value" placeholder="请选择" @change="switchType"
                            style="width: 115px;">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div> -->
                    <div class="search-input-wrap">
                        <el-input placeholder="请输入搜索内容" v-model="searchKey" @keyup.enter.native="search">
                        </el-input>
                    </div>
                    <el-button class="search-btn" type="warning" @click="search">
                        <img src="../assets/img/searchicon.png" alt="" style="width: 29px;" />
    
                    </el-button>
                </div>
            </div>
            
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {
                searchKey: '',
                // OA文章、OA图书、课件、视频、数据集
                options: [
                {
                    value: 0,
                    label: '全部'
                },
                {
                    value: 1,
                    label: 'OA文章'
                },
                {
                    value: 2,
                    label: 'OA图书'
                },
                {
                    value: 3,
                    label: '课件'
                },
                {
                    value: 4,
                    label: '视频'
                },
                {
                    value: 5,
                    label: '数据集'
                }
            ],
            value: 0,
            curSearchKey: '',
            }
        },
        watch: {
            $route: 'init'
        },
        methods: {
            init () {
                this.curSearchKey = this.$route.query.searchKey
                this.searchKey = ''
                if (this.$route.query.searchKey) {
                    this.searchKey = this.$route.query.searchKey
                }
            },
            search () {
                if (this.curSearchKey == this.searchKey) {
                    this.$router.replace({ path: '/pS/p', query: { searchKey: this.searchKey + ' ',  pN: encodeURI('哲学社会科学成果预印本平台') } })
                } else {
                    this.curSearchKey == this.searchKey
                    if (this.$route.name=='preprint') {
                        this.$router.push({ path: '/pS/p', query: { searchKey: this.searchKey,  pN: encodeURI('哲学社会科学成果预印本平台') } })
                    } else {
                        this.$router.replace({ path: '/pS/p', query: { searchKey: this.searchKey + ' ',  pN: encodeURI('哲学社会科学成果预印本平台') } })
                    }
                    
                }
            },
        },
        created () {
            this.init()
        }

    }
</script>

<style lang="scss" scoped>
.header-preprint-component {
    width: 100%;
    height: 400px;
    background: url(../assets/img/oa/preprint-banner.png);
    background-size: 100% 100%;
    float: left;
    color: #fff;
    .header-preprint-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title-logo {
            width: 937px;
            margin-top: 108px;
        }
        .search-input {
            width: 1016px;
            height: 96px;
            padding: 8px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 4px;
            box-sizing: border-box;
            margin-top: 40px;
        }
        .search-border-wrap {
            .search-icon {
                font-size: 26px;
                line-height: 0.46rem;
                color: #fff;
                cursor: pointer;
            }
            .search-btn {
                padding: 0 52px;
                border-radius: 0%;
                float: right;
                color: #fff;
                font-weight: 700;
            }
        }
    }
}
</style>